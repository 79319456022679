import _ from 'lodash';
import moment from 'moment';

/**
 * Migraatioiden defaulttistartpäivämäärä:
 * uusia rivejä tes-dataa lisättäessä merkataan ne suoraan alkamaan tästä päivästä.
 * Joudutaan ehkä rivikohtaisesti säätämään.
 */

export const START_DATE_DEFAULT = '1970-01-01';

const tesConstantKeys = {
    /**
     * Maksetaanko sairausajan palkka suunnitelluista työtunneista
     */
    PAY_FOR_SCHEDULED_SICK_LEAVE_HOURS: 'pay_for_scheduled_sick_leave_hours',

    /**
     * Käyttääkö TES lauantain yhtenä lomapäivänä
     */
    APPLIES_SATURDAY_AS_HOLIDAY_DAY: 'applies_saturday_as_holiday_day',

    /**
     * Lomakorvauksen 1. tason prosentti (alle vuoden työsuhde)
     * Rakennusalalla tämä prosentti aina 18.5 (9/2016
     */
    HOLIDAY_COMPENSATION_PERCENTAGE_INCOMPLETE_WORKING_YEAR: 'holiday_compensation_percentage_incomplete_working_year',

    /**
     * Lomakorvauksen 2. tason prosentti (väh. vuoden työsuhde): ei vielä käytössä missään
     * Rakennusalalla tämä prosentti aina 18.5 (9/2016
     */
    HOLIDAY_COMPENSATION_PERCENTAGE_FULL_WORKING_YEAR: 'holiday_compensation_percentage_full_working_year',

    /**
     * Lomarahan TES asetuksia:
     *
     * holiday_bonus_paid_with_holiday_compensation-key:
     * Maksetaanko lomaraha myös lomakorvauksesta (jos lomarahan maksaminen sopimuksella päällä):
     * - Hetalla maksetaan jos väh. 4kk kestänyt sopimus
     * - Työsopimuslaissa maksetaan
     * - Maralla ei makseta (ei vielä toteutettu)
     *
     * holiday_bonus_excluded_contract_termination_reasons-key:
     * Millä sopimuksen päättymisen syyllä EI makseta lomarahoja, jos niitä yleensäkkin maksetaan
     */
    HOLIDAY_BONUS_SETTINGS: 'holiday_bonus_settings',

    /**
     * Valitsee mikä toteutus on käytössä lisien laskennassa
     */
    TES_IMPLEMENTATION_ALLOWANCES: 'tes_implementation_allowances',

    /*
     * Lisien aikarajat: normaali työaika
     */
    WORK_TIME_LIMIT_NORMAL: 'work_time_limit_normal',

    /**
     * Lisien aikarajat: iltalisä
     */
    WORK_TIME_LIMIT_EVENING: 'work_time_limit_evening',

    /**
     * Lisien aikarajat: yölisä
     */
    WORK_TIME_LIMIT_NIGHT: 'work_time_limit_night',

    /**
     * Lisien aikarajat: launtailisä
     */
    WORK_TIME_LIMIT_SATURDAY: 'work_time_limit_saturday',

    /**
     * Lisien aikarajat: sunnuntailisä
     */
    WORK_TIME_LIMIT_SUNDAY: 'work_time_limit_sunday',

    /**
     * Lisien aikarajat: aattokorvaus
     */
    WORK_TIME_LIMIT_EVE: 'work_time_limit_eve',

    /**
     * Oletuspalkka (ei vielä käytössä)
     */
    DEFAULT_SALARY: 'default_salary',

    /**
     * Iltalisä prosentti Esim. '15%'
     */
    ALLOWANCE_EVENING: 'evening_allowance',

    /**
     * Yölisän prosentti. Esim: '30%'
     */
    ALLOWANCE_NIGHT: 'night_allowance',

    /**
     * Launtailisän prosentti. Esim: '20%'
     */
    ALLOWANCE_SATURDAY: 'saturday_allowance',

    /**
     * Sunnuntailisän prosentti. Esim: 100%
     */
    ALLOWANCE_SUNDAY: 'sunday_allowance',

    /**
     * Aattokorvauksen prosentti. Esim: 100%
     */
    ALLOWANCE_EVE: 'eve_allowance',

    /**
     * Aattoiltalisä
     */
    ALLOWANCE_EVE_EVENING: 'eve_evening_allowance',

    /**
     * Arkipyhälisä
     * @deprecated ei käytetä enään. midweek_holiday listasta tulee nyt myös arkipyhälisän määrä
     */
    ALLOWANCE_MIDWEEK: 'midweek_allowance',

    /**
     * Äitiys-/isyyslomat: lomaa kerryttävien äitiys- tai isyyslomapäivien maksimimäärä vuodessa.
     */
    MATERNITY_LEAVE_DAYS_AS_WORKTIME: 'maternity_leave_days_as_work_time',

    /**
     * Tilapäinen hoitovapaa: montako peräkkäistä päivää enintään (0 = ei sallittu)
     */
    TEMPORARY_NURSING_LEAVE_MAX_CONSECUTIVE_DAYS: 'temporary_nursing_leave_max_consecutive_days',

    /**
     * Kokemuslisäportaat
     */
    WORK_EXPERIENCE_LEVELS: 'work_experience_levels',

    /**
     * Koeajan maksimikesto kuukausina
     */
    MAX_TRIAL_PERIOD_LENGTH: 'max_trial_period_length',

    /**
     * Uuden tes toteutuksen mukainen kk palkan divisoori
     * OPH:ssa kertoo miten kk-palkkio jaetaan päiväpalkkioksi
     */
    NEW_DIVISOR_MONTHLY_SALARY: 'new_divisor_monthly_salary',

    /**
     * KK-palkkajakaja (työtunteja / kk)
     */
    DIVISOR_MONTHLY_SALARY_WORKHOURS: 'divisor_monthly_salary_workhours',

    /**
     * Työpäiviä viikossa -jakaja
     */
    DIVISOR_WORKDAYS_PER_WEEK: 'divisor_workdays_per_week',

    /**
     * Viikkoja kuukaudessa -jakaja
     */
    DIVISOR_WEEKS_PER_MONTH: 'divisor_weeks_per_month',

    /**
     * TES:n rajoitteet (esim. sallitut sopimustyypit, palkanmaksujaksot, palkanmaksupäivät)
     */

    /**
     * TES:llä sallitut sopimustyypit ja näille sallitut palkkatyypit (esim.
     * urakkasopimuksella palkka on kiinteä).
     */
    RESTRICTION_CONTRACT_TYPE_AND_SALARY_TYPE: 'restriction_contract_type_and_salary_type',

    /**
     * TES:llä sallitut lomienmaksamistavat (sopimuksen tyyppi rajoittaa)
     */
    RESTRICTION_CONTRACT_TYPE_AND_HOLIDAY_PAY_METHOD: 'restriction_contract_type_and_holiday_pay_method',

    /**
     * TES:llä sallitut palkkatyypit (tunti, kuukausi, ...) ja näille sallitut palkanmaksujaksot
     * (kerran kuussa, kahdesti kuussa, ...)
     */
    RESTRICTION_SALARY_TYPE_AND_PAY_PERIOD_LENGTH: 'restriction_salary_type_and_pay_period_length',

    /**
     * TES:llä sallitut palkanmaksujaksot ja näille sallitut palkanmaksupäivät
     */
    RESTRICTION_PAY_PERIOD_LENGTH_AND_PAY_DAY_MODE: 'restriction_pay_period_length_and_pay_day_mode',

    /**
     * Lisäkorvaukset ja TES-pohjaiset erikoiskorvaukset (esim. rakennusalan työkalukorvaus)
     */

    /**
     * Kokopäiväraha
     */
    COMPENSATION_PER_DIEM: 'compensation_per_diem',

    /**
     * Osapäiväraha
     */
    COMPENSATION_PER_DIEM_PARTIAL: 'compensation_per_diem_partial',

    /**
     *  Ulkomaan päiväraha
     */
    COMPENSATION_FOREIGN_PER_DIEM: 'compensation_foreign_per_diem',

    /**
     * Työkalukorvaus (rakennusalan TES)
     */
    COMPENSATION_TOOL: 'compensation_tool',

    /**
     * Työajanlyhennyskorvaus (rakennusalan TES)
     */
    COMPENSATION_SHORTENED_HOURS: 'compensation_shortened_hours',

    /**
     * Kilometrikorvaus (Verohallinnon säätelemä, esim. 0.41e / 2017)
     */
    COMPENSATION_KILOMETRE_ALLOWANCE: 'compensation_kilometre_allowance',

    /**
     * Matkustuskulut / "päivittäisten matkakustannusten korvaukset". Veroton!
     *
     * Huomaa: käytössä rakennusalalla (voidaan maksaa kilometrikorvauksen lisäksi).
     */
    COMPENSATION_TRAVELLING_EXPENSE: 'compensation_travelling_expense',

    /**
     * Ateriakorvaus (1 ateria)
     */
    COMPENSATION_MEAL_1: 'compensation_meal_1',

    /**
     * Ateriakorvaus (2 ateriaa)
     */
    COMPENSATION_MEAL_2: 'compensation_meal_2',

    /**
     * Puhelinetu
     */
    COMPENSATION_PHONE: 'compensation_phone',

    /**
     * Arkipyhät
     */
    MIDWEEK_HOLIDAY: 'midweek_holiday',

    /**
     * Ohita päällekkäiset lisät
     */
    OMIT_OVERLAPPING_ALLOWANCES: 'omit_overlapping_allowances',

    /**
     * Maksetaanko arkipyhälisää
     * @deprecated ei käytetä enään. arkipyhälisä maksetaan jos arkipyhä on valittu midweek_holiday listaan
     */
    PAY_ARKIPYHALISA: 'pay_arkipyhalisa',

    /**
     * Maksetaanko arkipyhäkorvausta
     * @deprecated korvattu TESConstantKeys::ARKIPYHAKORVAUS constantilla
     */
    PAY_ARKIPYHAKORVAUS: 'pay_arkipyhakorvaus',

    /**
     * Lista arkipyhistä joilta maksetaan arkipyhäkorvaus
     */
    ARKIPYHAKORVAUS: 'arkipyhakorvaus',

    /**
     * Maksetaanko aattolisää arkipyhäaatoilta
     * @deprecated poistettu käytöstä.
     */
    PAY_EVE_ALLOWANCE: 'pay_eve_allowance',

    /**
     * Arkipyhäaatot
     */
    MIDWEEK_HOLIDAY_EVE: 'midweek_holiday_eve',

    /**
     * Työvuorokauden alkuaika
     */
    WORK_DAY_START_TIME: 'work_day_start_time',

    /**
     * Sunnuntait eli punaiset päivät kalenterissa
     *
     * Constantin alla on sekä sunnuntai että aatto ajurin valinta, lisättävät sunnuntait ja aattopäivät
     */
    CALENDAR_RED_DAYS: 'calendar_red_days',

    /**
     * Kiintiöylityö
     *
     * sisältää kauden pituuden ja maksimi tuntimäärän / kausi
     */
    QUOTA_OVERTIME: 'quota_overtime',

    /**
     * Takuupalkka
     *
     * @deprecated
     */
    GUARANTEED_SALARY: 'guaranteed_salary',

    /**
     * Koeajan palkan alennus
     */
    TRIAL_PERIOD_REDUCED_SALARY: 'trial_period_reduced_salary',

    /**
     * Salaariresolvoinnin asetuksia: määrittää, että mitä pyritään laskemaan kta:lla jos mahdollista.
     */
    SALARY_RESOLVING_SETTINGS: 'salary_resolving_settings',

    /**
    * Keskituntiansion laskennan asetuksia
    */
    AVERAGE_HOURLY_SALARY_CALCULATION_DATA: 'average_hourly_salary_calculation_data',

    /**
     * Työaikaan tehtävät lepoajan vähennykset, json-pompsi tietokannassa
     */
    WORK_TIME_REST_DEDUCTION: 'work_time_rest_deduction',

    /**
     * Kertoo mistä aloitetaan laskemaan kuukausia arkipyhien laskussa.
     * Käytä PHP:n DateTimen modifyn älyämää stringiä ('x months/weeks/days)'.
     * Esim. '2 months' => laskee kaksi kuukautta taakseppäin
     * Laskee siis automaattisesti aikaa takseppäin, eli miinus merkkiä ei tarvi
     */
    ARKIPYHA_CALCULATION_START_INTERVAL: 'arkipyha_calculation_start_interval',

    /**
     * Kertoo mihin lopetetaan aikavälin laskeminen arkipyhiä laskettaessa. Eli annetusta
     * päivästä otetaan tämän verran modifyä ja saadaan loppupäivä.
     * Esim. Hetassa "Sunday last week". Tai ICT:ssä last day of previus month.
     */
    ARKIPYHA_CALCULATION_END_OFFSET: 'arkipyha_calculation_end_offset',

    /**
     * Kielilisän laskennan asetuksia
     */
    LANGUAGE_COMPENSATION_SETTINGS: 'language_compensation_settings',

    /**
     * Työajan lyhennysjärjestelmän tyyppi, kts. TESWorkTime
     */
    WORK_TIME_SHORTENING_SYSTEM: 'work_time_shortening_system',

    /**
     * Saikkujen maksusysteemi eli voi määrittää esim.
     * saikkujen kestoja, joista maksetaan palkkaa
     */
    SICK_LEAVE_PAYMENT_SYSTEM: 'sick_leave_payment_system',

    /**
     * Lomapäivien kerrytysasetukset jos TES noudattaa jotain työsopimuslaista poikkeavaa.
     * Huomaa: työsopimuslaki hardkoodattu (TES\HolidayAmounts)
     */
    HOLIDAY_DAY_EARNING_SETTINGS: 'holiday_day_earning_settings',

    /**
     * Ravintoetu
     */
    BENEFIT_MEAL: 'benefit_meal',

    /**
     * Palkan lisä, oma laji, kuukausi perusteinen
     *
     * @deprecated
     */
    MONTH_BASED_SALARY_ADDITION_INCREMENT: 'month_based_salary_addition_increment',

    /**
     * TES voi määrittää automaattisia lisiä, jotka kohdistuvat tiettyyn työaikatyyppiin
     *
     * Esim. RM pau & automaattinen 5% lisä normityötunneista koeajan jälkeen
     */
    AUTOGENERATED_ALLOWANCES: 'autogenerated_allowances',

    /**
     * Geneeriset vapaa-asetukset
     */
    HOLIDAY_DAY_GENERIC_SETTINGS: 'holiday_day_generic_settings',

    /**
     * Lomapäivien custom-kerrytysasetukset jos määritelty, osa edelleen hardkoodattu
     */
    HOLIDAY_DAY_GENERATION_SETTINGS: 'holiday_day_generation_settings',

    /**
     * Loma-/vapaapäivien yksikköhinnan resolvointiasetukset, toistaiseksi vain oph määrittää
     */
    HOLIDAY_DAY_PAYOUT_SETTINGS: 'holiday_day_payout_settings',

    /**
     * Vapaapäivien vanhenemisasetukset, oph:n käytössä
     */
    HOLIDAY_DAY_EXPIRATION_SETTINGS: 'holiday_day_expiration_settings',

    /**
     * Vapaapäivien kuukausikertymäasetukset (OPH-sopparit)
     */
    HOLIDAY_DAY_MODIFIER_SETTINGS: 'holiday_day_modifier_settings',

    /**
     * Lomapäiväkertoimia (esim. vuosilomalain 14 päivän sääntö ja tekno-/metsä-tes)
     */
    HOLIDAY_DAY_COUNT_FACTORS: 'holiday_day_count_factors',

    /**
     * Työaikojen ositusasetukset, koskee oph-sopimuksia. Ei liity siis mitenkään esim. arkiksiin tai ilta- ja yötöihin.
     *
     * Huomaa: ei migratoitu olemassaoleville tesseille, jos ei määritelty niin palkkionlaskenta käyttää
     * oletustoteutusta (OPHWorkTimeGrouperDefault).
     */
    WORK_TIME_GROUPER_SETTINGS: 'work_time_grouper_settings',
};

const tesConstantMap = {
    [tesConstantKeys.PAY_FOR_SCHEDULED_SICK_LEAVE_HOURS]: _trans(
        'tes_builder.constants.pay_for_scheduled_sick_leave_hours'
    ),
    [tesConstantKeys.APPLIES_SATURDAY_AS_HOLIDAY_DAY]: _trans('tes_builder.constants.applies_saturday_as_holiday_day'),
    [tesConstantKeys.HOLIDAY_COMPENSATION_PERCENTAGE_INCOMPLETE_WORKING_YEAR]: _trans(
        'tes_builder.constants.holiday_compensation_percentage_incomplete_working_year'
    ),
    [tesConstantKeys.HOLIDAY_COMPENSATION_PERCENTAGE_FULL_WORKING_YEAR]: _trans(
        'tes_builder.constants.holiday_compensation_percentage_full_working_year'
    ),
    [tesConstantKeys.HOLIDAY_BONUS_SETTINGS]: _trans(
        'tes_builder.constants.holiday_bonus_paid_with_holiday_compensation.label'
    ),
    [tesConstantKeys.TES_IMPLEMENTATION_ALLOWANCES]: _trans('tes_builder.constants.tes_implementation_allowances'),
    [tesConstantKeys.WORK_TIME_LIMIT_NORMAL]: _trans('tes_builder.constants.work_time_limit_normal'),
    [tesConstantKeys.WORK_TIME_LIMIT_EVENING]: _trans('tes_builder.constants.work_time_limit_evening'),
    [tesConstantKeys.WORK_TIME_LIMIT_NIGHT]: _trans('tes_builder.constants.work_time_limit_night'),
    [tesConstantKeys.WORK_TIME_LIMIT_SATURDAY]: _trans('tes_builder.constants.work_time_limit_saturday'),
    [tesConstantKeys.WORK_TIME_LIMIT_SUNDAY]: _trans('tes_builder.constants.work_time_limit_sunday'),
    [tesConstantKeys.WORK_TIME_LIMIT_EVE]: _trans('tes_builder.constants.work_time_limit_eve'),
    [tesConstantKeys.DEFAULT_SALARY]: _trans('tes_builder.constants.default_salary'),
    [tesConstantKeys.ALLOWANCE_EVENING]: _trans('tes_builder.constants.evening_allowance'),
    [tesConstantKeys.ALLOWANCE_NIGHT]: _trans('tes_builder.constants.night_allowance'),
    [tesConstantKeys.ALLOWANCE_SATURDAY]: _trans('tes_builder.constants.saturday_allowance'),
    [tesConstantKeys.ALLOWANCE_SUNDAY]: _trans('tes_builder.constants.sunday_allowance'),
    [tesConstantKeys.ALLOWANCE_EVE]: _trans('tes_builder.constants.eve_allowance'),
    [tesConstantKeys.ALLOWANCE_EVE_EVENING]: _trans('tes_builder.constants.eve_evening_allowance'),
    [tesConstantKeys.ALLOWANCE_MIDWEEK]: _trans('tes_builder.constants.midweek_allowance'),
    [tesConstantKeys.MATERNITY_LEAVE_DAYS_AS_WORKTIME]: _trans(
        'tes_builder.constants.maternity_leave_days_as_work_time'
    ),
    [tesConstantKeys.TEMPORARY_NURSING_LEAVE_MAX_CONSECUTIVE_DAYS]: _trans(
        'tes_builder.constants.temporary_nursing_leave_max_consecutive_days'
    ),
    [tesConstantKeys.WORK_EXPERIENCE_LEVELS]: _trans('tes_builder.constants.work_experience_levels'),
    [tesConstantKeys.MAX_TRIAL_PERIOD_LENGTH]: _trans('tes_builder.constants.max_trial_period_length'),
    [tesConstantKeys.DIVISOR_MONTHLY_SALARY_WORKHOURS]: _trans(
        'tes_builder.constants.divisor_monthly_salary_workhours'
    ),
    [tesConstantKeys.DIVISOR_WORKDAYS_PER_WEEK]: _trans('tes_builder.constants.divisor_workdays_per_week'),
    [tesConstantKeys.DIVISOR_WEEKS_PER_MONTH]: _trans('tes_builder.constants.divisor_weeks_per_month'),
    [tesConstantKeys.RESTRICTION_CONTRACT_TYPE_AND_SALARY_TYPE]: _trans(
        'tes_builder.constants.restriction_contract_type_and_salary_type'
    ),
    [tesConstantKeys.RESTRICTION_CONTRACT_TYPE_AND_HOLIDAY_PAY_METHOD]: _trans(
        'tes_builder.constants.restriction_contract_type_and_holiday_pay_method'
    ),
    [tesConstantKeys.RESTRICTION_SALARY_TYPE_AND_PAY_PERIOD_LENGTH]: _trans(
        'tes_builder.constants.restriction_salary_type_and_pay_period_length'
    ),
    [tesConstantKeys.RESTRICTION_PAY_PERIOD_LENGTH_AND_PAY_DAY_MODE]: _trans(
        'tes_builder.constants.restriction_pay_period_length_and_pay_day_mode'
    ),
    [tesConstantKeys.COMPENSATION_PER_DIEM]: _trans('tes_builder.constants.compensation_per_diem'),
    [tesConstantKeys.COMPENSATION_PER_DIEM_PARTIAL]: _trans('tes_builder.constants.compensation_per_diem_partial'),
    [tesConstantKeys.COMPENSATION_TOOL]: _trans('tes_builder.constants.compensation_tool'),
    [tesConstantKeys.COMPENSATION_SHORTENED_HOURS]: _trans('tes_builder.constants.compensation_shortened_hours'),
    [tesConstantKeys.COMPENSATION_KILOMETRE_ALLOWANCE]: _trans(
        'tes_builder.constants.compensation_kilometre_allowance'
    ),
    [tesConstantKeys.COMPENSATION_TRAVELLING_EXPENSE]: _trans('tes_builder.constants.compensation_travelling_expense'),
    [tesConstantKeys.COMPENSATION_MEAL_1]: _trans('tes_builder.constants.compensation_meal_1'),
    [tesConstantKeys.COMPENSATION_MEAL_2]: _trans('tes_builder.constants.compensation_meal_2'),
    [tesConstantKeys.COMPENSATION_PHONE]: _trans('tes_builder.constants.compensation_phone'),
    [tesConstantKeys.MIDWEEK_HOLIDAY]: _trans('tes_builder.constants.midweek_holiday'),
    [tesConstantKeys.OMIT_OVERLAPPING_ALLOWANCES]: _trans('tes_builder.constants.omit_overlapping_allowances'),
    [tesConstantKeys.PAY_ARKIPYHALISA]: _trans('tes_builder.constants.pay_arkipyhalisa'),
    [tesConstantKeys.PAY_ARKIPYHAKORVAUS]: _trans('tes_builder.constants.pay_arkipyhakorvaus'),
    [tesConstantKeys.ARKIPYHAKORVAUS]: _trans('tes_builder.constants.arkipyhakorvaus'),
    [tesConstantKeys.PAY_EVE_ALLOWANCE]: _trans('tes_builder.constants.pay_eve_allowance'),
    [tesConstantKeys.MIDWEEK_HOLIDAY_EVE]: _trans('tes_builder.constants.midweek_holiday_eve.label'),
    [tesConstantKeys.WORK_DAY_START_TIME]: _trans('tes_builder.constants.work_day_start_time'),
    [tesConstantKeys.CALENDAR_RED_DAYS]: _trans('tes_builder.constants.calendar_red_days.label'),
    [tesConstantKeys.QUOTA_OVERTIME]: _trans('tes_builder.constants.quota_overtime.label'),
    [tesConstantKeys.GUARANTEED_SALARY]: _trans('tes_builder.constants.guaranteed_salary.label'),
    [tesConstantKeys.SALARY_RESOLVING_SETTINGS]: _trans('tes_builder.constants.salary_resolving_settings.label'),
    [tesConstantKeys.AVERAGE_HOURLY_SALARY_CALCULATION_DATA]: _trans('tes_builder.constants.average_hourly_salary_calculation_data.label'),
    [tesConstantKeys.WORK_TIME_REST_DEDUCTION]: _trans('tes_builder.work_time_rest_deduction.label'),
    [tesConstantKeys.ARKIPYHA_CALCULATION_START_INTERVAL]: _trans('tes_builder.arkipyha_calculation_start_interval.label'),
    [tesConstantKeys.ARKIPYHA_CALCULATION_END_OFFSET]: _trans('tes_builder.arkipyha_calculation_end_offset.label'),
    [tesConstantKeys.LANGUAGE_COMPENSATION_SETTINGS]: _trans('tes_builder.constants.language_compensation_settings.label'),
    [tesConstantKeys.COMPENSATION_FOREIGN_PER_DIEM]: _trans('tes_builder.constants.compensation_foreign_per_diem.label'),
    [tesConstantKeys.WORK_TIME_SHORTENING_SYSTEM]: _trans('tes_builder.constants.work_time_shortening_system.label'),
    [tesConstantKeys.SICK_LEAVE_PAYMENT_SYSTEM]: _trans('tes_builder.constants.sick_leave_payment_system.label'),
    [tesConstantKeys.HOLIDAY_DAY_EARNING_SETTINGS]: _trans('tes_builder.constants.holiday_day_earning_settings.label'),
    [tesConstantKeys.BENEFIT_MEAL]: _trans('tes_builder.constants.benefit_meal.label'),
    [tesConstantKeys.MONTH_BASED_SALARY_ADDITION_INCREMENT]: _trans('tes_builder.constants.month_based_salary_addition_increment.label'),
    [tesConstantKeys.AUTOGENERATED_ALLOWANCES]: _trans('tes_builder.constants.autogenerated_allowances.label'),
    [tesConstantKeys.HOLIDAY_DAY_GENERATION_SETTINGS]: _trans('tes_builder.constants.holiday_day_generation_settings.label'),
    [tesConstantKeys.HOLIDAY_DAY_COUNT_FACTORS]: _trans('tes_builder.constants.holiday_day_count_factors.label'),
};

export function translateTesConstant(constant) {
    return constant in tesConstantMap ? tesConstantMap[constant] : '';
}

export const kilometreAllowances = {
    car: _trans('tes_builder.constants.kilometre_allowance_keys.car'),
    motorboat_max_50hp: _trans('tes_builder.constants.kilometre_allowance_keys.motorboat_max_50hp'),
    motorboat_over_50hp: _trans('tes_builder.constants.kilometre_allowance_keys.motorboat_over_50hp'),
    snowmobile: _trans('tes_builder.constants.kilometre_allowance_keys.snowmobile'),
    atv: _trans('tes_builder.constants.kilometre_allowance_keys.atv'),
    motorcycle: _trans('tes_builder.constants.kilometre_allowance_keys.motorcycle'),
    moped: _trans('tes_builder.constants.kilometre_allowance_keys.moped'),
    other: _trans('tes_builder.constants.kilometre_allowance_keys.other'),
    passenger: _trans('tes_builder.constants.kilometre_allowance_keys.passenger'),
};

export function translateKilometreAllowance(key) {
    return key in kilometreAllowances ? kilometreAllowances[key] : '';
}

export const midweekHolidays = [
    'newYearsDay',
    'epiphany',
    'goodFriday',
    'easterMonday',
    'mayDay',
    'ascensionDay',
    'midSummerEve',
    'finlandNationalDay',
    'christmasEve',
    'christmasDay',
    'boxingDay',
];

export const midweekHolidayEveDays = [
    'epiphany',
    'goodFriday',
    'easter',
    'mayDay',
    'ascensionDay',
    'midSummerEve',
    'finlandNationalDay',
    'christmasEve',
    'newYearsDay',
];

const midweekHolidayMap = {
    newYearsDay: _trans('tes_builder.midweek_holidays.newYearsDay'),
    epiphany: _trans('tes_builder.midweek_holidays.epiphany'),
    goodFriday: _trans('tes_builder.midweek_holidays.goodFriday'),
    easterMonday: _trans('tes_builder.midweek_holidays.easterMonday'),
    mayDay: _trans('tes_builder.midweek_holidays.mayDay'),
    ascensionDay: _trans('tes_builder.midweek_holidays.ascensionDay'),
    midSummerEve: _trans('tes_builder.midweek_holidays.midSummerEve'),
    finlandNationalDay: _trans('tes_builder.midweek_holidays.finlandNationalDay'),
    christmasEve: _trans('tes_builder.midweek_holidays.christmasEve'),
    christmasDay: _trans('tes_builder.midweek_holidays.christmasDay'),
    boxingDay: _trans('tes_builder.midweek_holidays.boxingDay'),
};

const midweekHolidayEveDayMap = {
    epiphany: _trans('tes_builder.midweek_holiday_eves.epiphany'),
    goodFriday: _trans('tes_builder.midweek_holiday_eves.goodFriday'),
    easter: _trans('tes_builder.midweek_holiday_eves.easter'),
    mayDay: _trans('tes_builder.midweek_holiday_eves.mayDay'),
    ascensionDay: _trans('tes_builder.midweek_holiday_eves.ascensionDay'),
    midSummerEve: _trans('tes_builder.midweek_holiday_eves.midSummerEve'),
    finlandNationalDay: _trans('tes_builder.midweek_holiday_eves.finlandNationalDay'),
    christmasEve: _trans('tes_builder.midweek_holiday_eves.christmasEve'),
    newYearsDay: _trans('tes_builder.midweek_holiday_eves.newYearsDay'),
};

export function translateMidweekHoliday(holiday) {
    return holiday in midweekHolidayMap ? midweekHolidayMap[holiday] : '';
}

export function translateMidweekHolidayEve(eve) {
    return eve in midweekHolidayEveDayMap ? midweekHolidayEveDayMap[eve] : '';
}

export function mergeMasterTESConstants(tes) {
    const constants = {};
    const constantList = [];
    do {
        constantList.unshift(tes.constants);
        tes = tes.masterCollectiveAgreement;
    } while (tes);
    _.forEach(constantList, (constantSet) => {
        _.forEach(constantSet, (item) => {
            // otetaan mukaan vain voimassaolevat constantit
            if (shouldAddConstant(item)) {
                constants[item.key] = { ...item };
            }
        });
    });
    return constants;
}

function shouldAddConstant(constant) {
    const startDate = moment(constant.startDate);
    const endDate = constant.endDate ? moment(constant.endDate) : null;

    if (endDate) {
        return moment().isBetween(startDate, endDate);
    }

    return moment().isAfter(startDate);
}

export default tesConstantKeys;
